.extend {
    background-color: white;
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
}

.extend_inner_container {
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/* 
.tabs_container>div>div>div {
    padding-left: 2rem !important;
} */

.graph_container {}


.container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.container>div {
    flex: 1;
}