.container {
    background: #F4F5FD;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main {
    display: flex;
    flex-flow: column;
    gap: 1em;
    height: 100%;
    flex: 1 1 auto;
    padding: 1em;
}

.options_container {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1em;
    margin-right: 2em;
}