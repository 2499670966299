.main {
  border-radius: 6px;
  display: flex;
  background-color: rgba(246, 244, 255, 0.3);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.main:hover {
  scale: 1.02;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.leftContainer {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(246, 244, 255, 0.7);
  border-radius: 6px;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem;
}
.rightContainer > span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
