.btn {
    cursor: pointer;
    display: flex;
    height: fit-content;
    gap: 0.3em;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    position: relative;
    transition: all 0.2s ease-in-out;
    padding: 0 !important;
    line-height: 1;
}

.btn::before {
    background-color: var(--before-color)
}

.btn::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -1px;
    left: 0;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: all .3s ease-in-out 0s;
}

.btn:hover::before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.btn:focus {
    outline: none !important;
}