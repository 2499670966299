.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.main {
    display: flex;
    flex-direction: column;
    margin-top: 75px;
}