.container {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.container>div {
    flex: 1;
    color: #777;
    font-size: 12px;
    padding: 0.3rem;
}

.action_container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.action_btn {
    width: 18px !important;
    height: 18px !important;
    background-color: #4431bb !important;
    color: white !important;
}

.delete_btn {
    background-color: red !important;
}

/* .input_container {
    padding: 0.3rem 0.6rem !important;
} */
.input {
    width: 80%;
}

.save_btn {
    border-color: #4431bb !important;
    color: #4431bb !important;
}