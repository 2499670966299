.container {
    width: 100%;
    /* height: 100vh; */
    /* border-radius: 22px; */
    display: flex;
    flex-direction: column;
    margin-top: 75px;
    height: calc(100% - 75px);
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 22px;
    background: #FBFCFC;
    /* padding: 2rem; */
    /* height: 95vh; */
    width: 100%;
    /* overflow-y: auto; */
    scrollbar-width: none;
    gap: 1.5rem;
    position: relative;
    flex: 1;
    padding: 2rem;
}

@media screen and (max-width:767px) {
    .main {
        padding: 1rem;
    }
}