.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.main {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 0 3rem;
}

.btn_container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: center;
}

@media screen and (max-width:900px) {
    .main {
        padding: 0 1rem;
    }

    .btn_container {
        flex-direction: row;
        align-items: center;
    }
}

.btn {
    background-color: #4431BB !important;
    text-transform: none !important;
}