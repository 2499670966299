.container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.8rem;
    border-radius: 4px;
    border: 1px dashed #4431B8;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(176, 176, 176, 0.10), 0px 3px 7px 0px rgba(176, 176, 176, 0.10), 0px 29px 17px 0px rgba(176, 176, 176, 0.05), 0px 52px 21px 0px rgba(176, 176, 176, 0.01), 0px 81px 23px 0px rgba(176, 176, 176, 0.00);
}

.title {
    color: #252525;
    font-size: 18px;
    font-weight: 500;
}

.main {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.input {
    flex: 1 !important;
    border-radius: 2px !important;
    border: none !important;
    background: #F2F2F2 !important;
    padding: 7px 10px !important;
    min-width: 100px !important;
}

.title_input {
    flex: 1 !important;
    border-radius: 2px !important;
    border: none !important;
    padding: 7px 0px 7px 10px !important;
    min-width: 100px !important;
}

.title_input:hover {
    box-shadow: none Im !important;
    outline: #4431B8 !important;
}

.btns {
    background: #4431B8 !important;
    text-transform: none !important;
}

.btns:disabled {
    color: white !important;
    background-color: #4431B8 !important;
    opacity: 0.8 !important;
}

.reset_btn {
    background-color: red !important;
    text-transform: none !important;
}