.varient_1 {
  box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
    0 0.126rem 0.225rem rgba(122, 123, 151, 0.3) !important;
  transition: transform 0.3s ease-in-out !important;
}

.varient_1:hover {
  /* background-color: #acc8f8; */
  transform: scale(1.1) !important; /* You can adjust the scale factor as needed */
}

.varient_1:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}
