.contianer {
    padding: 1rem;
    background-color: #fff;
    height: 100%;
}

.top_bar {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

.top_bar>div {
    display: flex;
    gap: 1rem;
    align-items: center;
}