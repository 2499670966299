.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.label {
    /* color: var(--secondary-text); */
    /* font-size: clamp(9px, 1.5vw, 12px); */
    line-height: 1;
}