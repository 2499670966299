@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');

.container {
    width: 100%;
    min-height: 100vh;
    background: #F4F7F7;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex: 1;
    gap: 2rem;
}

@media screen and (max-width:900px) {
    .main {
        grid-template-columns: auto;
    }

}

.main>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 32px;
    background: #FBFCFC;
    padding: 2rem;
    max-width: 440px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form_legend {
    color: var(--primary-text);
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 48px;
}

.sub_legend {
    color: var(--primary-text);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
}

.grid_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}


.form_actions_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.signin_btn {
    border-radius: 12px !important;
    padding: 0.7rem 3rem !important;
}


.input {
    padding-top: 0;
    padding-bottom: 0;
    /* background: #FFF !important; */
    border-radius: 5px !important;
}

.input :global .MuiOutlinedInput-notchedOutline {
    border-radius: 5px !important;
    border: 1px solid #DCDBDD !important;
}


.phone_code {
    font-size: 0.75rem !important;
}

.form :global .MuiInput-underline::before,
.form :global .MuiInput-underline::after {
    border-bottom: none !important;
}

.input :global .MuiSelect-select:focus {
    background-color: transparent !important;
}

.form :global .MuiOutlinedInput-adornedStart {
    padding-left: 5px !important;
}

.date_field_label {
    background: white !important;
    padding: 0 4px !important;
}


.caption {
    color: var(--secondary-text);
    font-family: Poppins;
    font-size: 12px;
    line-height: 17px;
    /* 141.667% */
}

.caption a {
    color: var(--primary);
}



/* left text  */
.left_header {
    color: var(--primary-text);
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    line-height: 48px;
}


.trust_text {
    color: var(--primary-text);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
}

.call_text {
    color: var(--primary-text);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
}