
.left_inner {
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff;
  padding: 1.5rem;
}


@media screen and (max-width: 900px) {



  .left_inner{
    padding: 1rem;
  }
}

.submitButton {
  padding: 0.6rem 0 !important;
  font-size: 18px !important;
  background-color: #3c44b1 !important;
  color: white !important;
}

.submitButton:disabled {
  background-color: var(--primary) !important;
  opacity: 0.5 !important;
  color: white !important;
}

.MainHeading {
  font-weight: 600;
  font-size: 25px !important;
  margin: 1.5rem 0 1rem 0;
}
.left_inner_desc {
  padding:1rem 0 1.5rem 0 !important;
  line-height: 170%;
}


.MsgContainer{
  max-height: 300px;
  min-height: 300px;
  overflow-y: auto;
  margin: 0 0 1rem 0;
}