.container {
    height: 74px;
    background: #fff;
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: fixed;
    right: 0;
    left: 0;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding: 0 !important;
}


.main {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}

.brand {
    background-color: #06113c;
    height: 74px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    width: 200px;
}

.brand>img {
    height: 30px;
}

.mobi_logo {
    display: none;
}

@media screen and (max-width: 767px) {
    .mobi_logo {
        display: block;
    }

    .desk_logo {
        display: none;
    }

    .brand {
        width: auto;
    }
}