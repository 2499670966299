.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.user_container {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

.user_container>img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.user_name {
    color: var(--primary-text);
    font-size: 14px;
    font-weight: 500;
}

.date_time {
    color: var(--secondary-text);
    font-size: 10.833px;
}

.note_container {
    color: var(--primary-text);
    font-size: 13px;
    white-space: normal;
}

.private_chip {
    text-align: center;
    padding: 1px 8px;
    font-size: 8px;
    color: white;
    background-color: var(--primary);
    border-radius: 10px;
}