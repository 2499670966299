.outer_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.iframe {
    border: none;
    display: block;
    margin: 0 auto;
}

.d_iframe {
    width: 100%;
    height: 100%;
    border: none;
}

iframe body {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iframe svg,
.iframe img {
    width: 100% !important;
    height: 100% !important;
}