.container {
    padding: 3rem;
}

.form_container {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
}

.input_container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.label {
    color: #313131;
    font-size: clamp(11px, 1.5vw, 14px);
}

.input {
    padding: 10px 12px;
    border-radius: 2.359px;
    border: 0.649px solid #8F8F8F;
}

.input:hover {
    border-color: #4413BB;
}

.input:focus {
    border-color: #4413BB;
}