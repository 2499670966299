.container {
    position: absolute;
    top: calc(100% + 1rem);
    right: 1rem;
    width: 80%;
    max-height: 800px;
    opacity: 1;
    z-index: 15;
    overflow: hidden;
    transition: all 0.2s ease-in;
    box-shadow: 0px 0px 0px 0px rgba(38, 38, 38, 0.10), 0px 7px 15px 0px rgba(142, 141, 147, 0.10), 0px 28px 28px 0px rgba(142, 141, 147, 0.09), 0px 62px 37px 0px rgba(142, 141, 147, 0.05), 0px 110px 44px 0px rgba(142, 141, 147, 0.01), 0px 173px 48px 0px rgba(142, 141, 147, 0.00);
    border-radius: 6px;
}

.collapse {
    opacity: 0;
    max-height: 0;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1rem;
    border-radius: 6px;
    border: 1px solid #EAEAEA;
    background: #FFF;
}

.popup_title {
    /* color: var(--primary-text); */
    text-align: center;
    /* font-size: 14px; */
    /* font-weight: 600; */
}

.header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.user_container {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

.user_container>img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.user_name {
    color: var(--primary-text);
    font-size: 14px;
    font-weight: 500;
}

.date_time {
    color: var(--secondary-text);
    font-size: 10.833px;
}





.action_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

}