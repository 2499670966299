.container {
  /* background: linear-gradient(
    180deg,
    #07113d -23.07%,
    #c4baff -16.6%,
    #ffffff 100%
  ); */
  background: rgb(202,205,246);
  background: linear-gradient(180deg, rgba(202,205,246,1) 0%, rgba(255,255,255,1) 70%);
  padding: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container > span {
  text-align: center;
}

.iconBg {
  background: rgba(68, 49, 184, 0.14);
  border-radius: 50%;
  padding: 1rem;
}
