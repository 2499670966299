.container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 400px;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.close_btn {
    position: absolute !important;
    top: 1rem !important;
    right: 1rem !important;
}

.btn_disabled:disabled {
    color: white !important;
    background-color: var(--danger) !important;
    opacity: 0.8 !important;
}

