.Box {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 0.3px solid rgba(68, 49, 184, 0.12);
}

.Box:hover {
  scale: 1.02;
  border: none;
  /* border: 1px solid var(--primary); */
  /* box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
      0 0.126rem 0.225rem rgba(122, 123, 151, 0.3); */
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
