.main {
  padding: 1rem 3rem;
  width: 100%;
  /* position: relative; */
}
.Header {
  justify-content: space-between;
}
.TwoFieldContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 20px;
  margin: 10px 0;
}
.OneFieldContainer {
  margin: 10px 0;
}

.DatePicker :global .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px !important;
}
.DatePicker
  :global
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.2) !important;
}
.DatePicker
  :global
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.6) !important;
}

@media screen and (max-width: 767px) {
  .main {
    padding: 1rem 1rem !important;
  }
  .DatePicker :global .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 40px !important;
  }
  .DatePicker
    :global
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.2) !important;
  }
  .DatePicker
    :global
    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

@media screen and (max-width: 500px) {
  .TwoFieldContainer {
    grid-template-columns: 1fr;
  }
}



.resetButton{
  background-color: #ffffff !important;
  color: #3c44b1 !important;
  border: 1px solid #3c44b1 !important;
}
.resetButton:hover{
  background-color: #3c44b1 !important;
  color: #ffffff !important;
}