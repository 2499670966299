.container {
    width: 100%;
    min-height: 100vh;
    background: #F4F7F7;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
    background-size: cover;
    background-position: right;
}

.main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex: 1;
    gap: 2rem;
}

.main>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner_main {
    padding-left: 3rem;
    grid-row: 1;
}




/* text  */
.kargo_text {
    color: #FFF;
    font-family: Inter;
    font-size: clamp(20px, 2vw, 25.077px);
    font-weight: 400;
}

.plex_text {
    color: #FFF;
    font-family: Inter;
    font-size: clamp(20px, 2vw, 25.077px);
    font-weight: 700;
}

.text_bold_big {
    /* color: #202020;
    font-family: Poppins;
    font-size: clamp(22px, 3vw, 32px);
    font-weight: 500;
    line-height: clamp(32px, 4vw, 48px);
    text-align: center; */
    color: rgba(255, 255, 255, 0.80);
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
}

.trust_text {
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
}


.right_brand {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 3rem;
}

@media screen and (max-width:900px) {
    .main {
        grid-template-columns: auto;
    }

    .inner_main {
        grid-row: 2;
    }

    .inner_main {
        padding-left: 0;
    }

    .right_brand {
        padding-right: 0;
    }

}

@media screen and (max-width: 767px) {
    .container {
        padding: 1rem;
    }
}