//  Core

// Dropdown menu

.MuiMenu-paper {
  & .bg-composed-wrapper {
    margin: (-($dropdown-padding-y)) -1px -2px;
    z-index: 5;
    top: -1px;
  }

  & .scroll-area {
    height: 10rem;
  }
}

.dropdown-menu-sm {
  width: 9rem;
}

.dropdown-menu-md {
  width: 12rem;
}

.dropdown-menu-lg {
  width: 15rem;
}

.dropdown-menu-xl {
  width: 21rem;
}

.dropdown-menu-xxl {
  width: 30rem;
}

.dropdown-menu-xxxl {
  width: 40rem;
}

.dropdown-menu-4xl {
  width: 50rem;
}

.dropdown-toggle::after {
  position: relative;
  top: 2px;
}

// Custom Selectors and Autocomplete

.country-autocomplete {
  max-height: 300px;

  // Hide the first group subheader within the options menu - remove border
  ul > li:first-child > div {
    display: none;
  }

  .MuiAutocomplete-groupLabel {
    border-bottom: $border-color solid 2px;
    margin-bottom: 8px;
    font-style: italic;
    font-weight: $font-weight-bold;
    line-height: 20px;
    padding-bottom: 8px;
    color: rgba($body-color, 0.3) !important;
    content-visibility: hidden;
  }
}

// MUI styled select - dense
.styled-select-dense {
  .MuiSelect-root {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }
}
