.MainHeading {
  font-weight: 700;
  margin: 0 0 40px 0;
}

.left_inner {
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 10px;
  /* background: #FFF; */
  padding: 1.5rem 1.5rem 0 1.5rem;
}

@media screen and (max-width: 900px) {
  .left_inner {
    padding: 1rem;
  }
}

.form_head {
  color: #202020;
  /* font-family: Poppins, Roboto; */
  font-size: 17px;
  font-weight: 500;
  line-height: 168%;
}

.submitButton {
  padding: 5px 15px !important;
  font-size: 14px !important;
  background-color: #3c44b1 !important;
  color: white !important;
}

.submitButton:disabled {
  background-color: var(--primary) !important;
  opacity: 0.5 !important;
  color: white !important;
}

.AddButton {
  background-color: #ffffff !important;
  color: #3c44b1 !important;
  border: 1px solid #3c44b1 !important;
}
.AddButton:hover {
  background-color: #3c44b1 !important;
  color: #ffffff !important;
}

.rejectedDocBox {
  background-color: #8080804f !important;
  margin: 0 0 2rem 0 !important;
}

.mainStepperContaier {
  padding: 0rem !important ;
  padding-bottom: 0rem !important;
  margin-bottom: -1.5rem;
}
.stepperContainer {
  /* background: #FFF; */
  /* padding: 1.5rem 1.5rem 0 1.5rem; */
  margin: 1rem 0;
  padding: 1rem;
}

.view_doc_btn {
  border-radius: 10px !important;
  padding: 6px 15px !important;
  font-size: 0.8rem !important;
}
