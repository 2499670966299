.container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: max-content;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    position: relative;
}

.list_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/* pagination css overide  */
.pagination :global .Mui-selected {
    background-color: var(--primary) !important;
    color: #fff !important;
    box-shadow: none !important;
}

.pagination :global .Mui-selected:hover {
    background-color: var(--primary) !important;
    color: #fff !important;
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3) !important;
}

.pagination :global .MuiPaginationItem-root {
    border: 1px solid var(--color-divider) !important;
    border-radius: 7px !important;
}

.pagination :global .MuiPagination-ul {
    gap: 0.7rem;
}