.container {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding: 1rem 2rem;
    border-radius: 4.098px;
    background: rgba(236, 238, 248, 0.60);
}

.title {
    color: var(--primary-text);
    font-weight: 500;
    font-size: clamp(12px, 2vw, 14px);
}

.top_details {
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 0.7rem 2rem;
    width: 100%;
}

.top_details>div {
    flex: 1;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0.7rem 1rem;
}

.label {
    color: var(--primary-text);
    font-size: clamp(10px, 1.8vw, 12.4px);
    font-weight: 500;
}

.value {
    color: var(--secondary-text);
    font-size: clamp(10px, 1.8vw, 12.4px);
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.simple_grid {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0.7rem 1rem;
}

.actions_container {
    display: flex;
    align-items: center;
    gap: 1rem;
}