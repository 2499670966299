/* Common styles for both origin and destination */
.origin,
.destination {
    margin-left: 24px;
    position: relative;
}

/* Styles for the origin class */
.origin {
    padding-bottom: 16px;
}

.origin::before {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: -24px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary);
    z-index: 2;
}

.origin::after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: -20px;
    width: 2px;
    height: 100%;
    background-color: var(--primary);
    z-index: 1;
}

/* Styles for the destination class */
.destination {
    margin-bottom: 16px;
}

.destination::before {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: -24px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary);
    z-index: 2;
}

.font_sizer {
    display: flex;
    font-size: 12px !important;
    /* line-height: 50%; */
    max-width: 200px;
    align-items: center;
}

@media only screen and (max-width: 767px) {

    .font_sizer {
        font-size: 12px !important;
    }

    .origin {
        padding-bottom: 6px;
    }

    .origin::before,
    .destination::before {
        width: 6px;
        height: 6px;
        top: 10px;
    }

    .origin::before,
    .destination::before {
        left: -12px;
    }

    .origin::after {
        left: -10px;
        top: 10px;
    }
}