.popup_container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 6;
}

.normal_container {}

.normal_container>div {
    height: auto;
    overflow: auto;
}

.normal_container>div>div {
    margin-top: 0;
}

.hidden {
    display: none !important;

}

.overflow_container {
    overflow-y: auto;
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 74px;
    padding: 1rem 3rem;
}


.main {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

@media screen and (max-width:700px) {
    .container {
        padding: 1rem;
    }

    .top_bar_container {
        flex-direction: column !important;
    }
}

.nav_and_detail {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.back_btn {
    background: #4431bb !important;
    color: white !important;
    height: 28px !important;
    width: 28px !important;
}

.detail_text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}