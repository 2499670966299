.container {
    position: absolute;
    top: calc(100% + 1rem);
    right: 1rem;
    width: 100%;
    max-height: 800px;
    opacity: 1;
    z-index: 15;
    overflow: hidden;
    transition: all 0.2s ease-in;
    box-shadow: 0px 0px 0px 0px rgba(38, 38, 38, 0.10), 0px 7px 15px 0px rgba(142, 141, 147, 0.10), 0px 28px 28px 0px rgba(142, 141, 147, 0.09), 0px 62px 37px 0px rgba(142, 141, 147, 0.05), 0px 110px 44px 0px rgba(142, 141, 147, 0.01), 0px 173px 48px 0px rgba(142, 141, 147, 0.00);
}

.collapse {
    opacity: 0;
    max-height: 0;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    position: relative;
    border-radius: 8px;
    border: 1px solid #DFDFDF;
    background: #F8F8FF;
}

.popup_title {
    color: var(--primary);
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid #4431B873;
}

.popup_title>span {
    position: relative;
}

.popup_title>span::before {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background: var(--primary);
}

.header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.user_container {
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.user_container>img {
    border-radius: 6px;
    width: 45px;
    height: 45px;
}

.user_name {
    color: var(--primary-text);
    font-size: 11px;
    font-weight: 600;
}

.date_time {
    color: var(--secondary-text);
    font-size: 10.833px;
}

.booking_status_chip {
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 13.578px;
    background: rgba(68, 49, 184, 0.14);
    font-size: 12.994px;
}

.flex_col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.re_enter_container {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.re_enter_label {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    color: #4B4B4B;
    font-size: 14px;
}

.re_enter_class {
    max-width: 270px !important;
}

.re_enter_container :global .MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
}

.re_enter_container .re_enter_class :global .MuiOutlinedInput-input {
    padding: 10px 30px 10px 7px !important;
}

.port_change_container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.field {
    max-width: 200px !important;
}

.action_container {
    display: flex;
    align-items: center;
    justify-content: end;

}