.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  position: relative;
  height: calc(100% - 75px);
}

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 22px;
  background: #fbfcfc;
  width: 100%;
  scrollbar-width: none;
  flex: 1;
  gap: 1.5rem;
  padding: 2rem;
}

.right_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 20%;
  max-height: 70vh;
}

@media screen and (max-width:900px) {
    .container {
        padding: 0;
    }
    .main {
        grid-template-columns: auto;
        padding: 1rem;
    }
    .right_container{
        display: none;
    }
    .main>div {
        padding: 0;
    }
}