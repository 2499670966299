.page_title {}

.page_title_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


@media screen and (max-width: 767px) {
    .page_title h1 {
        font-size: 17px !important;
    }

    .page_title_container{
        align-items: start;
    }

    .page_title_desc {
        font-size: 11px !important;
    }
}