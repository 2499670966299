.container {
    width: 100%;
    height: 100vh;
    border-radius: 22px;
    padding: 1.5rem 2.5rem 2.5rem 2.5rem;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 22px;
    background: #FBFCFC;
    padding: 2rem;
    height: 95vh;
    width: 100%;
    overflow-y: auto;
    scrollbar-width: none;
}