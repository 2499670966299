.mobile_logo {
    display: none;
    cursor: pointer;
}

@media screen and (max-width:767px) {
    .slider_btn {
        left: 10px !important;
    }

    .app_header_pane {
        /* margin-left: 15px; */
        margin-left: 25px;
    }

    .mobile_logo {
        display: block;
        height: 30px;
        width: 30px;
        margin-right: 7px;
    }
}