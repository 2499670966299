.container {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
}

.table {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.headers {
    display: flex;
    gap: 0.3rem;
}

.headers>span {
    flex: 100%;
    font-size: 15px;
    padding: 0.3rem 0.7rem;
    font-weight: 600;
}

.detail_row {
    display: flex;
    gap: 0.3rem;
}

.detail_row>span {
    flex: 100%;
    font-size: 14px;
    padding: 0.3rem 0.7rem;
}