.container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.close_btn {
    position: absolute !important;
    top: 1rem !important;
    right: 1rem !important;
}

.btn_disabled:disabled {
    color: white !important;
    background-color: var(--danger) !important;
    opacity: 0.8 !important;
}


.radio_label {
    font-size: 14px;
    color: var(--primary-text);
}

@media screen and (max-width: 767px) {
    .radio_label {
        font-size: 10px;
    }
}