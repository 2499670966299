.container {
    width: 100%;
    height: 100vh;
    border-radius: 22px;
    padding: 1.5rem 2.5rem 2.5rem 2.5rem;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 22px;
    background: #FBFCFC;
    padding: 2rem;
    height: 95vh;
    width: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    position: relative;
    gap: 1.5rem;
}

.main>* {
    text-align: center;
}

.reason_box {
    padding: 1.5rem;
    background: #4331bb0f;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
}


.logout_btn_container {
    position: absolute;
    top: 1rem;
    right: 2rem;
}