.container {
    max-width: 380px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    min-width: 270px;
    border-radius: 7px;
    border: 1px solid #EEE;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(158, 158, 158, 0.10), 0px 8px 18px 0px rgba(158, 158, 158, 0.10), 0px 32px 32px 0px rgba(158, 158, 158, 0.09), 0px 73px 44px 0px rgba(158, 158, 158, 0.05), 0px 130px 52px 0px rgba(158, 158, 158, 0.01), 0px 202px 57px 0px rgba(158, 158, 158, 0.00);
}

.heading {
    color: #252525;
    font-size: 18px;
    font-weight: 500;
}

.flex_justify_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_col {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.time_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.detail_text {
    color: #252525;
    font-size: 14px;
}

.detail_text_bold {
    color: #252525;
    font-size: 14px;
    font-weight: 500;
}

.detail_text_total {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
}

.suggested_price {
    border-radius: 1.66px;
    border: 0.44px dashed #4431B8;
    display: flex;
    justify-content: space-between;
    padding: 0.55em 1em;
}

.suggested_price>span {
    color: #4431B8;
    font-size: 12px;
    font-weight: 500;
}

.add_form {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.input {
    border-radius: 3.355px;
    background: #F6F7FB;
    padding: 8px 10px;
    border: none;
    color: #666;
    font-size: 13.5px;
}