.container {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: rgba(4, 207, 82, 0.30);
    box-shadow: 0px 0px 0px 0px rgba(66, 66, 66, 0.10), 0px 9px 20px 0px rgba(66, 66, 66, 0.10), 0px 36px 36px 0px rgba(66, 66, 66, 0.09), 0px 81px 49px 0px rgba(66, 66, 66, 0.05), 0px 144px 58px 0px rgba(66, 66, 66, 0.01), 0px 226px 63px 0px rgba(66, 66, 66, 0.00);
}

.header {
    display: flex;
    justify-content: center;
    padding: 0.5rem;
}

.header>span {
    color: var(--primary-text);
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 600;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding: 1rem;
}

.profile_box {
    flex: 1;
    display: flex;
    gap: 2rem;
    padding: 0.7rem;
    border-radius: 4.448px;
    background: #FFF;
    justify-content: space-between;
}

.avatar>img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.user_name_container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.user_name {
    color: var(--primary-text);
    font-family: Inter;
    font-size: 8.8px;
    font-weight: 500;
}

.user_role {
    color: var(--secondary-text);
    font-family: Inter;
    font-size: 5.867px;
}

.action_container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.action_btn {
    width: 14px !important;
    height: 14px !important;
}

.white_divider {
    width: 100%;
    height: 1px;
    background-color: white;
}

@media screen and (max-width:767px) {
    .header>span {
        font-size: 0.72rem;
    }
}