.card {
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.AddButton {
  background-color: #ffffff !important;
  color: #3c44b1 !important;
  border: 1px solid #3c44b1 !important;
}
.AddButton:hover {
  background-color: #3c44b1 !important;
  color: #ffffff !important;
}

.docContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .docContainer {
    grid-template-columns: 1fr;
  }
  .card {
    padding: 1rem 1rem 0 1rem;
  }
}
