.chat_btn {
    padding: 0.5rem;
    border-radius: 50%;
    background-color: white;
    transition: all 0.2s ease-in-out;
}

.chat_btn:hover {
    background-color: #4331bb0e;
}

@media screen and (max-width:767px) {
    .container {
        /* padding: 3rem 1.5rem 3rem 2rem !important; */
        padding: 2rem 1rem !important;
    }
}