// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
  }
}

.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}

.min-h-lg {
  min-height: 150px !important;
}

.max-h-lg {
  max-height: 500px !important;
}

// Viewport additional helpers

.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}

.minw-lg {
  min-width: 1300px !important;
}

// .minw-200 {
//   min-width: 200px !important;
// }

// .minw-250 {
//   min-width: 250px !important;
// }

.min-h-200 {
  min-height: 200px !important;
}

$num: 50;
@while $num <= 600 {
  .minw-#{$num} {
    min-width: $num + 0px !important;
  }
  .maxw-#{$num} {
    max-width: $num + 0px !important;
  }
  $num: $num + 50;
}

.min-h-auto {
  min-height: auto !important;
}

// @include media-breakpoint-down(lg) {
//   .MuiGrid-item {
//     width: 100%;
//   }
// }

// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 1100px,
// xl: 1381px
