.container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1500;
}

.inner_container {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.main {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 120px);
    /* overflow-y: auto; */
    padding: 2rem;
    border-radius: 8px;
    background-color: white;
    position: relative;
    scrollbar-width: thin;
    /* width: 80%; */
}

@media screen and (max-width: 700px) {
    .main {
        width: 98%;
    }
}

.main>div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.close_btn {
    height: 25px !important;
    width: 25px !important;
    position: absolute !important;
    top: 0.5rem !important;
    right: 0.5rem !important;
    background-color: var(--primary) !important;
}

.edit_btn {
    height: 25px !important;
    width: 25px !important;
    position: absolute !important;
    top: 0.5rem !important;
    right: 3rem !important;
    background-color: var(--primary) !important;
}