.container :global .font-size-xs {
    font-size: 0.83333rem !important;
}

.grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
}

.route_text {
    font-size: 0.85rem;
}

.management_container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    border-radius: 12px;
    background: rgba(68, 49, 184, 0.06);
}

.dates_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.date_field_container {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.date_field_container>span {
    color: var(--secondary-text);
    font-size: 12px;
}

.date_field_container>input {
    color: var(--secondary-text);
    font-size: 12px;
    padding: 5px;
    max-width: 200px;
    width: 100%;
}

@media screen and (max-width:767px) {
    .container :global .font-size-xs {
        font-size: 0.7rem !important;
    }

    .container :global .font-size-sm {
        font-size: 0.72rem !important;
    }

    .management_container {
        grid-template-columns: auto auto;
        padding: 1rem;
    }

    .add_service_btn {
        font-size: 0.6rem !important;
        padding: 4px 7px;
    }

    .dates_container {
        grid-template-columns: auto;
    }

    .container .status_popup_btn {
        font-size: 7px !important;
    }

    .route_icon {
        height: 0.4rem !important;
    }

    .route_text {
        font-size: 0.6rem !important;
    }
}