.container :global .font-size-md {
  font-size: 1rem !important;
}

.container :global .font-size-sm {
  font-size: 0.875rem !important;
}

.card_date {
  font-size: 80% !important;
}

.amountDisplay {
  font-size: 2.5rem;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .container :global .font-size-md {
    font-size: 0.833rem !important;
  }

  .container :global .font-size-sm {
    font-size: 0.7rem !important;
  }

  .badge {
    font-size: 0.5rem !important;
  }

  .card_date {
    font-size: 60% !important;
  }

  .card_logo {
    width: 45px !important;
  }

  .amount_heading > span {
    font-size: 1.3rem !important;
  }

  .amount_heading > small {
    font-size: 1.04rem !important;
  }
  .amountDisplay {
    line-height: 0.5;
  }
}
