.container {
  /* background: #f4f5fd; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 1rem 0 0 0; */
  /* min-height: 100vh; */
  padding: 3rem;
}

.main {
  /* max-width: 1100px; */
  display: flex;
  flex-direction: column;
  gap: 0rem;
  /* padding: 1rem 8rem; */
  flex: 1;
  width: 100%;
}

.top_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;
  background: #fff;
  padding: 0.8rem 2rem;
}

.top_bar > div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.search_input {
  border: none;
}

.filter_btn {
  height: 35px !important;
  width: 35px !important;
}

@media screen and (min-width: 700px) {
  .filter_btn {
    display: none !important;
  }
}

.content {
  display: flex;
  gap: 2rem;
  padding: 1rem 0;
  /* overflow: hidden; */
}

/* filter  */
.filter {
  min-width: 350px;
  /* border-radius: 0.75rem; */
  transition: all 0.2s ease-in-out;
  position: sticky;
  height: max-content;
  top: 90px;
}

.close_filter_btn {
  display: none !important;
}
@media screen and (max-width: 1000px) {
  .main {
    padding: 0rem 1rem;
  }
}

@media screen and (max-width: 700px) {
  .main {
    padding: 0rem;
  }
  .filter {
    position: fixed;
    z-index: 8;
    right: 0;
    top: calc(75px + 2rem);
    max-height: 75vh;
    overflow-y: auto;
    transform: translate(110%);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow-anchor: none;
    scrollbar-width: thin;
  }
  .container {
    padding: 1rem;
  }
  .close_filter_btn {
    display: block !important;
    position: absolute !important;
    right: 5px !important;
    top: 5px !important;
  }
}

.show_filter {
  right: 50%;
  transform: translate(50%);
}

/* inner details  */
.list_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1rem;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .btn {
    padding: 8px 12px !important;
    font-size: small !important;
  }
}
