.container {
  border-radius: 0.75rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  transition: scale 0.2s ease-in-out;
}
.container:hover {
  scale: 1.02;
  border: 1px solid var(--primary);
  box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
    0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
}
.flex_col {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  /* gap: 0.3rem; */
}

.top_container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 0;
}

.user_detail {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.bold {
  color: #252525;
  font-size: clamp(10px, 1.5vw, 13px);
  font-weight: 600;
}

.top_bar_label {
  color: #696969;
  font-size: clamp(10px, 1.5vw, 13px);
  font-weight: 600;
}

.top_bar_value {
  color: #434343;
  font-size: clamp(9px, 1.5vw, 12px);
  font-weight: 500;
}

.middle_container {
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 0;
  /* flex-wrap: wrap; */
}

@media screen and (max-width: 700px) {
  .middle_container {
    flex-wrap: wrap;
  }
}
.middle_details {
  padding: 0 0 0 1rem;
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: 0.5rem;
  /* width: 100%; */
}

.middle_details_label {
  color: #696969;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.middle_details_value {
  color: #434343;
  font-size: 0.8rem;
  font-weight: 500;
  /* max-width: 280px; */
}

.container_detail {
  color: var(--secondary-text);
  font-size: clamp(8px, 1.5vw, 10.5px);
  font-weight: 500;
}

.bottom_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  gap: 2rem;
  flex-wrap: wrap;
}

.make_offer_btn {
  color: #4431bb !important;
  text-transform: none !important;
  border-radius: 4.612px !important;
  border: 0.86px solid #3b44b1 !important;
  background: rgba(59, 68, 177, 0.06) !important;
}

.make_offer_btn:hover {
  background: rgba(47, 57, 178, 0.186) !important;
}

.chat_btn {
  padding: 0.5rem;
  border-radius: 50%;
  background-color: white;
  transition: all 0.2s ease-in-out;
}

.chat_btn:hover {
  background-color: #4331bb0e;
}

.more_services {
  /* color: white; */
  /* background-color: var(--primary); */
  color: var(--primary);
  border: 1px solid var(--primary);
  padding: 1px 5px;
  font-size: 10px;
  border-radius: 7px;
  cursor: pointer;
}
