.container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
}

.top_bar {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.person_name {
    color: var(--primary-text);
    font-size: clamp(13px, 5vw, 16px);
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.main {
    flex: 1;
    border-radius: 22px 22px 0 0;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(190, 190, 190, 0.12);
}

.more_container {
    position: relative;
}

.more_popup {
    /* position: absolute;
    top: 3rem;
    right: 0;
    max-height: 200px;
    max-width: 300px; */
    border-radius: 5px;
    background-color: #fff;
    /* overflow: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px; */
}

/* .hide_more_popup {
    border-radius: 50% 0 50% 50%;
    max-height: 0 !important;
    max-width: 0 !important;
} */

/* .more_popup>div {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    width: max-content;
    padding: 0.3rem 0;
} */

.more_popup>div {
    padding: 0.6rem 1.5rem;
    color: var(--primary);
    border-right: 3px solid transparent;
    cursor: pointer;
}

.more_popup>div:hover {
    border-right: 3px solid var(--primary);
    background: rgba(68, 49, 184, 0.16);

}