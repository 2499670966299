.container {
    display: flex;
    gap: 0.8rem;
    align-items: center;

}

.label {
    color: var(--primary-text);
    font-size: 14px;
    font-weight: 600;
}