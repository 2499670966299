.container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem 1.5rem;
    max-width: 306px;
    position: relative;
    border-radius: 32px;
    box-shadow: 0px 0px 0px 0px rgba(68, 49, 184, 0.10), -1.4583333730697632px 2.9166667461395264px 7.2916669845581055px 0px rgba(68, 49, 184, 0.10), -5.833333492279053px 13.125px 14.583333969116211px 0px rgba(68, 49, 184, 0.09), -11.666666984558105px 29.166667938232422px 18.95833396911621px 0px rgba(68, 49, 184, 0.05), -20.416667938232422px 51.04166793823242px 21.875px 0px rgba(68, 49, 184, 0.01), -32.083335876464844px 80.20833587646484px 24.791667938232422px 0px rgba(68, 49, 184, 0.00);
}

.default {
    background: linear-gradient(224deg, #000628 0%, rgba(5, 25, 139, 0.19) 100%);

}

.purple {
    background: #C6B2EE;
}

.container_type_chip {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    padding: 6px 20px;
    border-radius: 28px;
    background: #FFF;
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.32);
}

.price_container {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.label {
    font-size: 17px;
    font-weight: 800;
}

.prev_price {
    font-size: 14px;
    font-weight: 500;
}

.dates_container {
    font-size: 12px;
    width: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.4rem 0.2rem;
}

.current_price_container {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1rem 2.5rem 1rem 2rem;
    border-radius: 16px;
    background: #FFF;
    position: relative;
}

.current_price_container>.dates_container {
    font-weight: 600;
}

.current_price {
    color: #575757;
    font-size: 16px;
    font-weight: 900;
}

.action_btn {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0.4rem 0 0.4rem 0.8rem;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    border-radius: 16px 0px 0px 16px;
    cursor: pointer;
}

.purple .current_text {
    color: #C6B2EE;
}

.purple .action_btn {
    background-color: #C6B2EE;
}

.default .current_text {
    color: #31386E;
}

.default .action_btn {
    background-color: #31386E;
}