.container {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.table_container {
    /* min-width: fit-content; */
    min-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 6px;
    background: #FFF;
    padding: 1rem;
    padding-bottom: 2rem;
}

.table_header {
    /* display: flex;
    background-color: aliceblue;
    border-radius: 12px 0px 0px 0px;
    background: #ECECEC;
    padding: 1em 0.5em;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    gap: 0.5rem;
}

.table_header>div {
    text-align: center;
    color: var(--primary-text);
    font-family: Inter;
    font-size: clamp(8px, 1.5vw, 12px);
    font-weight: 600;
    padding: 5px;
}

/* .table_header>div:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
    padding-left: 0.8rem;
} */

/* .table_header>div>* {
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: clamp(8px, 1.5vw, 12px) !important;
    font-weight: 500 !important;
} */