.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  position: relative;
  height: calc(100% - 75px);
}

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 22px;
  background: #fbfcfc;
  width: 100%;
  scrollbar-width: none;
  align-items: center;
  flex: 1;
  gap: 1.5rem;
  padding: 2rem;
}

/* .main>div {
    padding: 1rem 2rem;
} */

.right_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 20%;
  max-height: 70vh;
}
.left_inner {
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  background: #fff;
  padding: 1.5rem;
}

.inner_right {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 28.714px;
  background: #fff;
  padding: 1.5rem;
}

@media screen and (max-width: 900px) {
  .container {
    padding: 0;
  }

  .main {
    grid-template-columns: auto;
    padding: 1rem;
  }
  .right_container {
    display: none;
  }
  .main > div {
    padding: 0;
  }

  .left_inner,
  .inner_right {
    padding: 1rem;
  }
}

.submitButton {
  padding: 20px auto !important;
  font-size: 22px !important;
  background-color: #3c44b1 !important;
  color: white !important;
}

.submitButton:disabled {
  background-color: var(--primary) !important;
  opacity: 0.5 !important;
  color: white !important;
}

.left_container {
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: max-content;
}

.AddButton {
  background-color: #ffffff !important;
  color: #3c44b1 !important;
  border: 1px solid #3c44b1 !important;
}
.AddButton:hover {
  background-color: #3c44b1 !important;
  color: #ffffff !important;
}
.MainHeading {
  font-weight: 700;
  margin: 0 0 1.5rem 0;
}
.left_inner_desc {
  text-align: center;
  margin: 2rem 0;
}
.follow_text {
  text-align: center;
  margin: 2rem 0 1rem 0;
}
.bottom_divider {
  margin:1.5rem 0 0 0 !important;
}
